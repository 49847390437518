import React from 'react'

const Footer = (props) => {
  return (
    <>
    <div id="footer">
      <p className="footer-text">WhereWhenYouFlyV2</p>
      
      <p className="footer-text">{"\u00A9"} Junhao Yuan, Erika Moreno Blackburn, Eva Liu 2022</p>
    </div>
    </>
  )
}

export default Footer;